import { Component, OnInit } from '@angular/core';
import { Commons } from '../../Commons';
import { interval } from 'rxjs';

@Component({
  selector: 'app-weather-chart',
  templateUrl: './weather-chart.component.html',
  styleUrl: './weather-chart.component.scss'
})
export class WeatherChartComponent implements OnInit {
  weatherData: any;
  getScreenWidth: any;
  mobileWidth: number = Commons.MOBILE_WIDTH
  SECONDS_INTERVAL : number = 5
  MAX_WIND_SPEED:number=26

  constructor() { }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth
    // Ejemplo de uso:
    this.weatherData = this.generateWeatherData();

    // Para llamar al método cada 1 minuto especificar (60000 milisegundos)
    interval(this.SECONDS_INTERVAL * 1000).subscribe(() => {
      this.weatherData = this.generateWeatherData();
    });
  }

  generateWeatherData(): any {
    const randomNumber = (min: number, max: number) => Math.random() * (max - min) + min;
    const randomDateTime = () => new Date().toISOString();

    const weatherData = {
      data: [
        {
          station: "Estación Minera Tambillos",
          measurement_time: randomDateTime(),
          temperature: {
            value: randomNumber(7, 28),
            unit: "C°"
          },
          solar_radiation: {
            value: randomNumber(0.2, 1.5),
            unit: "MJ/m^2"
          },
          wind_speed: {
            value: randomNumber(2.7, 29),
            unit: "km/h"
          },
          wind_direction: "NNE",
          wind_gust: {
            value: randomNumber(2.7, 29),
            unit: "km/h"
          }
        },
        {
          station: "Estación Las Cardas",
          measurement_time: randomDateTime(),
          temperature: {
            value: randomNumber(7, 28),
            unit: "C°"
          },
          solar_radiation: {
            value: randomNumber(0.2, 1.5),
            unit: "MJ/m^2"
          },
          wind_speed: {
            value: randomNumber(2.7, 29),
            unit: "km/h"
          },
          wind_direction: "E",
          wind_gust: {
            value: randomNumber(2.7, 29),
            unit: "km/h"
          }
        }
      ]
    };

    return weatherData;
  }

  get isMobile(){
    return this.getScreenWidth < this.mobileWidth
  }

  windSpeedLimit(speed:number):boolean{
    return speed >= this.MAX_WIND_SPEED
  }

  get sessionIsOpen(){
    return Commons.sessionIsOpen()
  }

}
