import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Commons } from 'src/app/shared/Commons';

@Component({
  selector: 'app-fullscreen-alert',
  templateUrl: './fullscreen-alert.component.html',
  styleUrls: ['./fullscreen-alert.component.scss']
})
export class FullscreenAlertComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  get sessionOpen(){
    return Commons.sessionIsOpen();
  }

  openLink(){
    if(!this.sessionOpen){
      this.router.navigate([Commons.PATH_LOGIN])
    }else{
      this.router.navigate([Commons.PATH_PRODUCT])
    }
  }

}
