<div class="weather-data mb-5 mt-5">
    <h1 class="text-center mt-5">Visualización del Clima</h1>
    <div class="row">
            <div class="col-{{(isMobile) ? '12':'6'}}" *ngFor="let stationData of weatherData.data">
                <h2 class="text-center">{{ stationData.station }}</h2>
                <table class="mx-2">
                    <tbody>
                        <tr>
                            <td><b>Hora de Medición:</b></td>
                            <td>{{ stationData.measurement_time | date: 'hh:mm' }}</td>
                        </tr>
                        <tr>
                            <td><b>Temperatura (°C):</b></td>
                            <td>{{ stationData.temperature.value | number:'1.0-1' }}</td>
                        </tr>
                        <tr>
                            <td><b>Radiación Solar (MJ/m²):</b></td>
                            <td>{{ stationData.solar_radiation.value | number:'1.0-1' }}</td>
                        </tr>
                        <tr>
                            <td><b>Velocidad del Viento (km/h):</b></td>
                            <td *ngIf="windSpeedLimit(stationData.wind_speed.value)" class="text-danger text-center"><b>{{ stationData.wind_speed.value | number:'1.0-1' }}&nbsp;<i class="tf-ion-android-warning"></i></b></td>
                            <td *ngIf="!windSpeedLimit(stationData.wind_speed.value)" class="text-info text-center"><b>{{ stationData.wind_speed.value | number:'1.0-1' }}&nbsp;<i class="tf-ion-android-done"></i></b></td>
                        </tr>
                        <tr>
                            <td><b>Dirección del Viento:</b></td>
                            <td>{{ stationData.wind_direction }}</td>
                        </tr>
                        <tr>
                            <td><b>Ráfaga del Viento (km/h):</b></td>
                            <td>{{ stationData.wind_gust.value | number:'1.0-1' }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="bg-gray text-center mb-2 mx-2">
                    <span *ngIf="windSpeedLimit(stationData.wind_speed.value)"><b class="text-danger text-center"><i class="tf-ion-android-warning"></i>&nbsp;&nbsp;Viento excede umbral parametrizado</b></span>
                    <span *ngIf="!windSpeedLimit(stationData.wind_speed.value)"><b class="text-info text-center"><i class="tf-ion-android-done"></i>&nbsp;&nbsp;Sin alertas</b></span>
                </div>
            </div>
            <div *ngIf="sessionIsOpen" class="col-12 text-center mb-5 mt-5">
                <button class="btn btn-transparent">Historial de alertas</button>
            </div>
    </div>

</div>